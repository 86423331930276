/* eslint-disable no-undef */
/**
 * Initilize common settings
 */

const env = process.env.REACT_APP_ENVIRONMENT_NAME;
const baseURL = process.env.REACT_APP_PUBLIC_URL;

/**
 * Initilize b2c settings
 */

const b2cStageId = "746910bd-a061-4b52-96ce-1e9743d9e2a4";
const b2cProdId = "b2e76e51-ff41-4692-b802-26a393b72f92";
const b2cStageAuthority =
  "https://candidate-stg.accenture.com/4b4fa07b-0160-4951-9be6-4f352155aaf0/B2C_1A_SignIn_SmartScheduling_OIDC";
const b2cProdAuthority =
  "https://candidate.accenture.com/8c7f34d7-d0f8-4d82-b3cf-37eaf5c6774c/B2C_1A_SignIn_SmartScheduling_OIDC";

/**
 * Initilize azure settings
 */

const azStageId = "4e3ff5b5-d127-43b6-949e-25b12334eabe";
const azProdId = "fa311d2b-ca66-4644-a05d-ac82a3dd91b7";
const azDevId = "a91e0fde-c7cb-468d-85e7-500a70a4196b";
const azPerformanceId = "a0f81f04-9412-4c2d-b0ae-7c288ca1f5cb";
const azStageAuthority = "https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/";
const azPerformanceAuthority =
  "https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/";
const azProdAuthority = "https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb/";

/**
 * B2C configuration
 */

const b2cRedirectUrl = baseURL;
let b2cAuthority = null,
  b2cClientId = null;

if (env === "local" || env === "dev" || env === "stage") {
  b2cClientId = b2cStageId;
  b2cAuthority = b2cStageAuthority;
} else if (env === "prod") {
  b2cClientId = b2cProdId;
  b2cAuthority = b2cProdAuthority;
}

/**
 * Azure configuration
 */

let azureAuthority = null,
  azureClientId = null,
  apiURL = baseURL;

if (env === "local" || env === "stage") {
  azureClientId = azStageId;
  azureAuthority = azStageAuthority;
} else if (env === "dev") {
  azureClientId = azDevId;
  azureAuthority = azStageAuthority;
} else if (env === "performance") {
  azureClientId = azPerformanceId;
  azureAuthority = azPerformanceAuthority;
} else if (env === "prod") {
  azureClientId = azProdId;
  azureAuthority = azProdAuthority;
}

if (env === "local") apiURL = "https://interviewscheduling-stage.accenture.com";

/**
 * Bind settings
 */

export const appSettings = {
  apiUrl: apiURL + "/api/",
  b2cConfig: {
    auth: {
      clientId: b2cClientId,
      authority: b2cAuthority,
      redirectUri: b2cRedirectUrl,
      knownAuthorities: [b2cAuthority]
    }
  },
  azureConfig: {
    auth: {
      clientId: azureClientId,
      authority: azureAuthority,
      knownAuthorities: [azureAuthority]
    },
    system: {
      tokenRenewalOffsetSeconds: 300
    }
  }
};
