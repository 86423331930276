import { addLocale } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import React from "react";

/**
 * Session Keys
 */

export const sessionKEYS = {
  accessConfig: "accessConfig",
  roleInfo: "app-role-info",
  userType: "userType",
  userDetails: "userDetails",
  idToken: "msal.idtoken",
  currentTimeZoneId: "currentTimeZoneId",
  currentTimeGenaricName: "currentTimeGenaricName",
  currentTimeStdName: "currentTimeStdName",
  currentLanguage: "currentLanguage",
  candidatePrivacy: "candidatePrivacy",
  actionSource: "actionSourceId",
  configurationData: "configurationData",
  hambergerId: "hambergerId"
};

/**
 * Navigation URL's
 */

export const routePaths = {
  common: {
    landing: "landing",
    admin: "admin",
    candidate: "candidate",
    interviewer: "interviewer",
    scheduler: "scheduler"
  },
  landing: {
    dashboard: "/landing/dashboard"
  },
  admin: {
    dashboard: "/admin/dashboard",
    locationManagement: "/admin/location-management",
    entityManagement: "/admin/entity-management",
    masterDataManagement: "/admin/master-data-management",
    configurationTemplateManagement: "/admin/configuration-template-management",
    operationalLogs: "/admin/operational-logs"
  },
  candidate: {
    dashboard: "/candidate/dashboard",
    slotSelection: "/candidate/slot-selection"
  },
  interviewer: {
    dashboard: "/interviewer/dashboard",
    confirmedSlots: "/interviewer/confirmed-slots",
    awaitingSlots: "/interviewer/awaiting-slots",
    advancedAvailability: "/interviewer/advanced-availability",
    jdViewer: "/interviewer/jd-viewer"
  },
  scheduler: {
    dashboard: "/scheduler/dashboard",
    screeningDashboard: "/scheduler/screening-dashboard",
    bulkUpload: "/scheduler/bulk-upload",
    requisitionDetails: "/scheduler/requisition-details",
    screeningDetails: "/scheduler/screening-details",
    screeningAvailability: "/scheduler/screening-availability",
    candidateCaptureAvailability: "/scheduler/capture-candidate-availability"
  }
};

export const regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,3})$/i,
  phone: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  link: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
  special: /^[a-z0-9_]+$/i,
  input: /^[ A-Za-z0-9_@?=.+,/&:-]*$/,
  number: /^[0-9]+$/i,
  query: /^[ A-Za-z0-9_@.,/&+-]*$/,
  noSpecial: /^[\p{L}\p{M}\p{Zs}0-9\n.,-]{0,400}$/u
};

/**
 * comments here
 */

export const httpCode = {
  sucess: "200",
  denied: "403"
};

/**
 * comments here
 */

export const userTypes = {
  accenture: "Accenture Employee",
  external: "External Employee"
};

/**
 * comments here
 */

export const accEmails = ["accenture.com", "ds.dev.accenture.com"];

/**
 * comments here
 */

export const selectedDashboard = {
  admin: {
    adminDashboard: "adminDashboard"
  },
  candidate: {
    candidateDashboard: "candidateDashboard"
  },
  interviewer: {
    interviewerDashboard: "interviewerDashboard"
  },
  schedular: {
    schedularDashboard: "schedularDashboard"
  }
};

/**
 * API links
 */

export const apiLinks = {
  postTestMsal: "api/users/2",
  getUserRoles: "globalapi/api/AccessManagement/GetUserLoggedIn",
  getPrivacyStatement: "globalapi/api/Scheduler/GetPrivacyInformation",
  saveLanguage: "globalapi/api/Interviewer/SaveInterviewerlanguage",
  getMasterLanguageTimezone: "globalapi/api/candidate/GetCandidateTimezoneAndLanguage",
  getDuration: "globalapi/api/MasterData/GetDuration",
  getInterviewType: "globalapi/api/MasterData/GetSSInterviewTypes",
  getConfigurationData: "globalapi/api/MasterData/GetConfigurationData",
  scheduleGreenSlots: "globalapi/api/Candidate/ScheduleCandidateSelectedGreenSlot",
  scheduleAmberSlots: "globalapi/api/Candidate/ScheduleCandidateSelectedAmberSlot",
  scheduleBlueSlots: "globalapi/api/Candidate/ScheduleCandidateSelectedBlueSlot",
  rescheduleGreenSlots: "globalapi/api/Candidate/RescheduleCandidateSelectedGreenSlots",
  rescheduleAmberSlots: "globalapi/api/Candidate/RescheduleCandidateSelectedAmberSlots",
  rescheduleBlueSlots: "globalapi/api/Candidate/RescheduleCandidateSelectedBlueSlots",
  candidate: {
    getCandidateDetails: "globalapi/api/Candidate/GetBasicCandidateDetailsAndRequisitions",
    getCandidateInterviewJourney: "globalapi/api/Candidate/GetInterviewJourneyForCandidate",
    getCandidateNominatedSlots: "globalapi/api/Candidate/PostNominatedSlots",
    saveCandidateTimezoneAndLanguage: "globalapi/api/candidate/SaveCandidateTimezoneAndLanguage",
    candidateScheduleSlot: "globalapi/api/candidate/ScheduleCandidateSelectedSlot",
    candidateRescheduleSlot: "globalapi/api/candidate/RescheduleCandidateSelectedSlots",
    postCandidateProposedSlots: "globalapi/api/candidate/PostCandidateProposedSlots",
    getCandidateProposedAlternateSlots:
      "globalapi/api/candidate/GetCandidateProposedAlternateSlots",
    getFacilityDetails: "globalapi/api/Candidate/GetFacilityDetailsByRequisitionId",
    getEISlotDetails: "globalapi/api/Candidate/GetEligibleEIInterviewSlotDetails",
    validateCandidateSlots: "globalapi/api/Candidate/ValidateCandidateSlots"
  },
  interviewer: {
    getJobDescription: "globalapi/api/Interviewer/GetJobDescriptionByRequisitionId",
    updateWhatsNewDisplayFlag: "globalapi/api/Interviewer/postInterviewerConfigurationDetails",
    getFirstName: "globalapi/api/Interviewer/GetInterviewerDetails",
    getUpcomingScheduledInterview: "globalapi/api/Interviewer/GetUpcomingScheduledInterview",
    getConfirmedInterviewSlots: "globalapi/api/Interviewer/GetConfirmedInterviewerSlots",
    getAwaitingSlotConfirmation: "globalapi/api/Interviewer/GetAwaitingSlotConfirmation",
    getAllCounts: "globalapi/api/Interviewer/GetAllCounts",
    getCancellationReasons: "globalapi/api/InterviewsTab/GetCancellationReasons",
    getPeoplePickerInterviewerList: "globalapi/api/Interviewer/GetPeoplePickerInterviewerList",
    cancelInterviewForInterviewer: "globalapi/api/Interviewer/CancelInterviewForInterviewer",
    getNominatedSlotsForInterviewer: "globalapi/api/Interviewer/GetNominatedSlotsForInterviewer",
    rescheduleInterviewForInterviewer:
      "globalapi/api/Interviewer/RescheduleInterviewForInterviewer",
    interviewerAcceptSlots: "globalapi/api/Interviewer/InterviewerAcceptSlots",
    getListOfAvailibilityForInterviewer:
      "globalapi/api/Interviewer/GetListOfAvailibilityForInterviewer",
    postInterviewerTimezone: "globalapi/api/Interviewer/SaveInterviewerTimezoneAndPrivacy",
    SaveProposedAlternateslots: "globalapi/api/Interviewer/SaveProposedAlternateslots",
    DeleteAvailabilityForInterviewer: "globalapi/api/Interviewer/DeleteAvailabilityForInterviewer",
    InterviewerDecliningSlots: "globalapi/api/Interviewer/InterviewerDecliningSlots",
    saveInterviewerNominatedSlot: "globalapi/api/Interviewer/SaveInterviewerNominatedSlot",
    EditAdvanceAvailability: "/globalapi/api/Interviewer/EditAdvanceAvailability",
    getPanelDetails: "globalapi/api/Interviewer/GetInterviewDetailsForPanel",
    getFacilityDetailsByEIEnterpriseID:
      "globalapi/api/Interviewer/GetFacilityDetailsByEIEnterpriseID",
    getCandidateProposedSlots: "globalapi/api/Interviewer/GetCandidateProposedSlots",
    validateSlots: "globalapi/api/Interviewer/ValidateEditAndDeletedSlotsForScreening"
  },
  scheduler: {
    india: {
      getAccessConfiguration: "globalapi/api/AccessManagement/GetRoleAccess",
      getUserRoleForRequisition: "globalapi/api/scheduler/GetUserRoleForRequisition",
      getCandidateDetailsId:
        "globalapi/api/Scheduler/GetCandidateDetailsByRequisitionIdOrCandidateId",
      getCustomSlots: "globalapi/api/CaptureCandidateAvailability/GetProposedAlternateSlots",
      postCandidateProposedSlots:
        "globalapi/api/CaptureCandidateAvailability/PostProposedSlotsByScheduler",
      getInterviewerForIndiaPanel:
        "globalapi/api/BulkScheduling/GetInterviewerListForPanelBulkScheduling "
    },
    getSSInterviewMasterTypes: "globalapi/api/MasterData/GetSSInterviewTypes",
    getInterviewMasterModes: "globalapi/api/MasterData/GetInterviewModes",
    getInterviewerDetails:
      "globalapi/api/InterviewsTab/GetInterviewDetailsForInterviewsTabByRequisitionId",
    schedularDashboardLink: "globalapi/api/Scheduler/PostRecruitmentDetails",
    getAcceptedPrivacyStatementFlag: "globalapi/api/Scheduler/GetAcceptedPrivacyStatementFlag",
    postGlobalSearchRequisitions:
      "globalapi/api/Scheduler/PostGlobalSearchRequisitions?requisitionString=",
    getInterviewerSlotStatus: "globalapi/api/scheduler/GetInterviewerSlotStatusByRequisitionId",
    getReschedulerCancellationReason: "globalapi/api/InterviewsTab/GetCancellationReasons",
    postRecruiterSummary: "globalapi/api/Scheduler/PostRecruiterSummary",
    getCancellationReason: "globalapi/api/Scheduler/GetCancellationReasonsforSchedulerByReqId",
    getSequenceOfInterview: "globalapi/api/Scheduler/GetSequenceOfInterview?RequisitionId=",
    getCandidateDetails: "globalapi/api/CandidateTab/GetCandidateDetails",
    getPrescheduleTabDataByRequisitionId:
      "globalapi/api/preschedulingtab/GetPrescheduleTabDataByRequisitionId",
    getSSInterviewTypes: "globalapi/api/Scheduler/GetSSInterviewTypes",
    getInterviewModes: "globalapi/api/Scheduler/GetInterviewModes",
    getInterviewTools: "globalapi/api/Scheduler/GetInterviewTools",
    getDuration: "globalapi/api/Scheduler/GetDuration",
    updateShufflingSequenceOfInterview:
      "globalapi/api/Scheduler/UpdateShufflingSequenceOfInterview",
    postInterviewCancellation: "globalapi/api/InterviewsTab/PostInterviewCancellation",
    getSchedulingRoundsByRequisitionId:
      "globalapi/api/preschedulingtab/GetPreSchedulingRoundsByRequisitionId",
    getPreschedulingInputFields: "globalapi/api/preschedulingtab/GetPreschedulingInputFields",
    getPreschedulingPanelEditFields:
      "globalapi/api/preschedulingtab/GetPanelInterviewDetailsByPreschedulingInputDetailId",
    getPreschedulingCandidatesInfo: "globalapi/api/preschedulingtab/GetPreschedulingCandidatesInfo",
    getPreschedulingSearchInterviewersFilter:
      "globalapi/api/preschedulingtab/GetPreschedulingInputFieldsInterviewer?searchFilter=",
    getInterviewerNominatedSlots: "globalapi/api/preschedulingtab/GetInterviewerNominatedSlots",
    getPreschedulingInterviewLocations:
      "globalapi/api/preschedulingtab/GetPreSchedulingInputFieldsLocation",
    postPreSchedulingAddInput:
      "globalapi/api/preschedulingtab/PostPreSchedulingInputScheduleInterview",
    postPreSchedulingEditInput:
      "globalapi/api/preschedulingtab/PostPreSchedulingInputEditScheduleInterview",
    getInterviewerFreeSlots: "globalapi/api/preschedulingtab/GetInterviewerFreeSlots",
    postPrivacyInformation: "globalapi/api/Scheduler/PostPrivacyInformation?",
    getCandidatesInterviewDetailsForCandidatePipeline:
      "globalapi/api/CandidatePipelineTab/GetCandidatesInterviewDetailsForCandidatePipeline",
    postDeleteCandidatePipeline:
      "globalapi/api/CandidatePipelineTab/DeleteCandidatesInterviewDetailsForCandidatePipeline",
    updateCandidatePipeline:
      "globalapi/api/CandidatePipelineTab/UpdateCandidatesInterviewDetailsForCandidatePipeline",
    editPreSchedulingInput:
      "globalapi/api/preschedulingtab/GetPrescheduleTabDataByPreschedulingInputDetailId",
    getAdhocScheduleDetails: "globalapi/api/CandidateTab/GetAdhocScheduleDetails",
    getInterviewersList: "globalapi/api/CandidateTab/GetInterviewersListFromSearchString",
    postAdhocScheduleDetails: "globalapi/api/CandidateTab/PostAdhocScheduleDetails",
    getRescheduleDetails: "globalapi/api/CandidateTab/GetRescheduleDetails",
    postManualReschedule: "globalapi/api/CandidateTab/PostRescheduleDetails",
    submitWithdrawRequest:
      "globalapi/api/CandidateTab/WithDrawStatus?RequisitionCandidatePipelineId=",
    postAutoReschedule: "globalapi/api/CandidateTab/PostAutoReschedule",
    postMoveToNextRound:
      "globalapi/api/CandidateTab/MoveToNextRound?RequisitionCandidatePipelineId=",
    postMoveToNextStage:
      "globalapi/api/CandidateTab/UpdateMoveToNextStageOnWDStatus?requisitionCandidatePipelineId=",
    updateTimezoneDetails: "globalapi/api/Scheduler/UpdateTimezoneDetails",
    getTimezoneDetailsForNextLogin: "globalapi/api/Scheduler/GetTimezoneDetailsForNextLogin",
    getSchedulerTimezoneAndLanguage: "globalapi/api/Scheduler/GetTimezoneDetails",
    getSchedulerTimezoneDetails: "globalapi/api/Scheduler/GetTimezoneDetailsForNextLogin",
    validateInterviewer:
      "globalapi/api/preschedulingtab/GetPreSchedulingCandidateReferredByInterviewerValidation",
    getNextRoundInterviewType: "globalapi/api/CandidateTab/GetNextRoundsInterviewType",
    saveSchedulerlanguage: "globalapi/api/Scheduler/SaveSchedulerlanguage",
    getBulkCandidatesList: "globalapi/api/BulkScheduling/SearchCandidatesForBulkScheduling",
    postBulkCandidates: "globalapi/api/BulkScheduling/PostAddCandidatesForBulkSchedule",
    GetDraftOnSmartScheduling: "globalapi/api/BulkScheduling/GetDraftOnSmartScheduling",
    GetSentToWorkdayList: "/globalapi/api/BulkScheduling/GetSaveOnWorkdayStatus",
    GetBulkInterviewDetails:
      "globalapi/api/BulkScheduling/GetCandidateInterviewDetailsForBulkScheduling",
    postDraftToSsOrSaveToWorkDay: "globalapi/api/BulkScheduling/PostDraftToSsOrSaveToWorkDay",
    saveOnWorkday: "globalapi/api/BulkScheduling/PostSaveToWorkDay",
    resolveIssue: "globalapi/api/BulkScheduling/BulkDraftSchedulingResolveErrors",
    deleteBulkCandidate: "globalapi/api/BulkScheduling/RemoveCandidateFromBulkSchduleding",
    getSlotsForInterviewer: "globalapi/api/preschedulingtab/GetInterviewerSlotDetails",
    postPanelInterviewInputDetails: "globalapi/api/preschedulingtab/PostPanelInterviewInputDetails",
    postRescheduleCancelPanel:
      "globalapi/api/preschedulingTab/PostRescheduleCancelPanelInterviewInputDetails",
    GetSentToWDInterviewDetails:
      "globalapi/api/BulkScheduling/GetCandidateInterviewDetailsForBulkSchedulingSaveOnWd",
    postResolvePanelIssue: "globalapi/api/preschedulingtab/PostResolvePanelGroupIssue",
    getPanelCandidatesList: "globalapi/api/BulkScheduling/AddCandidatesToPanelInBulk",
    restrictSameInterviewer: "globalapi/api/preschedulingTab/RestrictSameInterviewerForJobApp",
    PostScreeningInterviewCancellation:
      "globalapi/api/InterviewsTab/PostScreeningInterviewCancellation",
    getEIsList: "globalapi/api/CandidateTab/GetEIsListFromSearchString",
    getSlotStatusByPreschedulingId:
      "globalapi/api/Scheduler/GetInterviewerSlotStatusByPreschedulingId"
  },
  admin: {
    getAdminConfigurationDetails: "globalApi/api/AccessManagement/GetAdminConfigurationDetails",
    getTemplateHierarchyRules: "globalapi/api/Admin/GetTemplateHierarchyRules",
    getSequenceOfInterviewForTemplates:
      "globalapi/api/Scheduler/GetSequenceOfInterviewForTemplates",
    insertTemplateHierarchyRules: "globalapi/api/Admin/InsertHierarchyRules",
    updateTemplateHierarchyRules: "globalapi/api/Admin/UpdateHierarchyRules",
    deleteTemplateHierarchyRules: "globalapi/api/Admin/DeleteHierarchyRules",
    validateHierarchyRules: "globalapi/api/Admin/ValidateHierarchyRules",
    getViewUsersList: "globalapi/api/Admin/GetViewUsersList",
    deleteUserAccess: "globalapi/api/Admin/DeleteViewUsersList",
    updateUserAccess: "globalapi/api/Admin/UpdateViewUsersList",
    addUser: "globalapi/api/Admin/PostAddNewUser",
    getSapID: "globalapi/api/Admin/GetSAPId",
    getOperationalLogDetails: "globalapi/api/Admin/GetOperationalLogDetails",
    getlocationManagment: "globalapi/api/LocationManagement/GetFieldsValues_TreeViewList",
    deleteLocation: "globalapi/api/LocationManagement/DeleteNewLocation",
    getInterviewModes: "globalapi/api/MasterData/GetInterviewModes",
    getInterviewTools: "globalapi/api/MasterData/GetInterviewTools",
    getQuestionnaires: "globalapi/api/MasterData/GetQuestionnaires",
    getLanguages: "globalapi/api/MasterData/GetLanguages",
    getCandidatePrioritizationRules: "globalapi/api/MasterData/GetCandidatePrioritizationRules",
    getQuestionnaireMappings: "globalapi/api/QuestionnaireMappingTab/GetQuestionnaireMappings",
    deleteQuestionnaireMappings:
      "globalapi/api/QuestionnaireMappingTab/DeleteQuestionnaireMappings",
    getSelectedChannelHierarchy: "globalapi/api/ChannelHierarchyTab/GetSelectedChannelHierarchy",
    getUnselectedChannelHierarchy:
      "globalapi/api/ChannelHierarchyTab/GetUnselectedChannelHierarchy",
    updateShufflingChannelHierarchy:
      "globalapi/api/ChannelHierarchyTab/UpdateShufflingChannelHierarchy",
    getSelectedCandidatePrioritizationRules:
      "globalapi/api/CandidatePrioritizationTab/GetSelectedCandidatePrioritizationRules",
    getUnselectedCandidatePrioritizationRules:
      "globalapi/api/CandidatePrioritizationTab/GetUnselectedCandidatePrioritizationRules",
    updateShufflingCandidatePrioritizationRules:
      "globalapi/api/CandidatePrioritizationTab/UpdateShufflingCandidatePrioritizationRules",
    getAdminPrivacyStatements: "globalapi/api/DataPrivacyTab/GetAdminPrivacyStatements",
    updateAdminPrivacyStatements: "globalapi/api/DataPrivacyTab/UpdateAdminPrivacyStatements",
    getSupportContacts: "globalapi/api/SchedulingMasterDataTab/GetSupportContacts",
    insertSupportContacts: "globalapi/api/SchedulingMasterDataTab/InsertSupportContacts",
    updateSupportContacts: "globalapi/api/SchedulingMasterDataTab/UpdateSupportContacts",
    deleteSupportContacts: "globalapi/api/SchedulingMasterDataTab/DeleteSupportContacts",
    getFieldsForHierarchy: "globalapi/api/Admin/GetFieldsForHierarchyRules",
    getHierarchy: "globalapi/api/Admin/GetFieldsForHierarchyRulesModified",
    getHierarchyForViewMode: "globalapi/api/Admin/ViewFieldsForHierarchyRulesModified",
    getConfigTemplateDuration: "globalapi/api/Scheduler/GetDuration",
    getConfigTemplateInterviewModes: "globalapi/api/Admin/GetInterviewModes",
    getConfigSSInterviewTypes: "globalapi/api/Admin/GetSSInterviewTypes",
    insertConfigurationTemplate: "globalapi/api/Admin/AddConfigTemplate",
    viewConfigurationTemplate: "globalapi/api/Admin/ViewConfigurationTemplate",
    updateConfigurationTemplate: "globalapi/api/Admin/EditConfigurationTemplate",
    getCandidateTimezoneAndLanguage: "globalapi/api/Candidate/GetCandidateTimezoneAndLanguage",
    addLocation: "globalapi/api/LocationManagement/AddLocation",
    viewLocationFields: "globalapi/api/LocationManagement/ViewLocationFields",
    updateLocation: "globalapi/api/LocationManagement/UpdateNewLocation",
    getEntityManagement: "globalapi/api/EntityManagement/GetEntityManagementGridView",
    getValuesAndIds: "globalapi/api/Admin/GetValuesAndIds",
    validateRuleSet: "globalapi/api/Admin/ValidateHierarchyRuleFlowSet",
    getEntity: "globalapi/api/Admin/GetEntityDetails",
    getCandidateTierType: "globalapi/api/Admin/GetCandidateTierandTypeDetails",
    getClient: "globalapi/api/Admin/GetClientDetails",
    getProject: "globalapi/api/Admin/GetProjectDetails",
    getEditEntities: "globalapi/api/EntityManagement/ViewEntityManagementConfiguration",
    getAutomatedScheduling: "globalapi/api/Admin/GetSSInterviewTypes",
    getSSInterviewTypes: "globalapi/api/Scheduler/GetSSInterviewTypes",
    GetFacilityDetailsByNodeId: "globalapi/api/Admin/GetFacilityDetailsByNodeId",
    getInterviewTypeModeToolDuration: "globalapi/api/Admin/GetInterviewTypeModeToolDuration",
    getFacilityCapacityHierarchy: "globalapi/api/MasterData/GetFacilityDetails",
    getAddEntities: "globalapi/api/EntityManagement/GetEntityMasterValues",
    postAddEntity: "globalapi/api/EntityManagement/PostAddEntity",
    updateEntity: "globalapi/api/EntityManagement/UpdateEntityDetail",
    deleteEntities: "globalapi/api/EntityManagement/DeleteEntityDetail",
    updateFacility: "globalapi/api/MasterData/UpdateFacilityDetails"
  },
  screening: {
    getCandidateDetailsForScreening: "globalapi/api/preschedulingtab/GetScreeningCandidatesInfo",
    postScreeningInput: "globalapi/api/preschedulingtab/PostScheduleScreeningInput",
    postRescheduleScreening: "/globalapi/api/CandidateTab/PostRescheduleScreening",
    getTabDetailsForScreening: "globalapi/api/CandidateTab/GetCandidateDetailsForScreening",
    getEditScreeningDetails:
      "globalapi/api/preschedulingtab/GetEditScreeningTabDataByPreschedulingInputDetailId",
    postEditScreeningInput: "globalapi/api/preschedulingtab/PostEditScreeningInputTabDetails",
    deleteAvailabilityForInterviewer: "globalapi/api/Interviewer/DeleteAvailabilityForInterviewer",
    validateRuleSet: "globalapi/api/Admin/ValidateHierarchyRuleFlowSet",
    getSSInterviewTypes: "globalapi/api/Scheduler/GetSSInterviewTypes"
  }
};

/**
 * comments here
 */

export const userRoutes = {
  internal: ["scheduler", "interviewer", "reports", "admin"],
  external: ["candidate"]
};

/**
 * comments here
 */

export const appEvents = {
  openErrorPopup: "openErrorPopup",
  toastMessages: "toastMessages",
  openLandingTermsOfUse: "openLandingTermsOfUse",
  Interviewer: {
    reloadInterviewModuleTables: "reloadInterviewModuleTables",
    openAcceptSlotModal: "openAcceptSlotModal",
    openDeclineSlotModal: "openDeclineSlotModal",
    toggleMenuEvent: "toggleMenuEvent_int",
    openJDModal: "openJDModal",
    openCancelModal: "openCancelModal",
    openRescheduleModal: "openRescheduleModal",
    openProposeAlternateSlotModal: "openProposeAlternateSlotModal",
    openDeleteAvailabilityModal: "openDeleteAvailabilityModal",
    openPrivacySubmitModal: "openPrivacySubmitModal",
    openPrivacyViewModal: "openPrivacyViewModal",
    openSupportModal: "openSupportModal",
    openWhatsNewModal: "openWhatsNewModal",
    openAddNominationsModal: "openAddNominationsModal",
    openEditAvailabilityModal: "openEditAvailabilityModal",
    openTermsOfUseModal: "openTermsOfUseModal",
    openRequisitionDetails: "openRequisitionDetails",
    openPanelInterviewDetails: "openPanelInterviewDetails",
    openTimezoneLanguageModal: "openTimezoneLanguageModal",
    refreshChart: "refreshChart",
    openNominatedAcceptSlotModal: "openNominatedAcceptSlotModal",
    openHeaderModal: "openHeaderModal"
  },
  admin: {
    reloadAdminModuleTables: "reloadAdminModuleTables",
    toggleMenuEvent: "toggleMenuEvent_admin",
    userAccessEditModal: "userAccessEditModal",
    userAccessAddModal: "userAccessAddModal",
    locationManagmentAddModal: "locationManagmentAddModal",
    locationManagmentViewModal: "locationManagmentViewModal",
    reloadScheduledTab: "reloadScheduledTab",
    locationManagmentEditModal: "locationManagmentEditModal",
    locationManagmentDeleteModal: "locationManagmentDeleteModal",
    userAccessDeleteModal: "userAccessDeleteModal",
    configurationAddModal: "configurationAddModal",
    reloadUserAccessTable: "reloadUserAccessTable",
    openMasterDataAddModel: "openMasterDataAddModel",
    openMasterDataEditModel: "openMasterDataEditModel",
    openMasterDataDeleteModel: "openMasterDataDeleteModel",
    configurationTemplateDeleteModal: "configurationTemplateDeleteModal",
    configurationSuperOrgLocationTreeModal: "configurationSuperOrgLocationTreeModal",
    openTermsOfUseModal: "openTermsOfUseModal",
    openSupportModal: "openSupportModal",
    entityManagementAddModal: "entityManagementAddModal",
    entityManagementViewModal: "entityManagementViewModal",
    entityManagementEditModal: "entityManagementEditModal",
    entityManagementDeleteModal: "entityManagementDeleteModal",
    openHeaderModal: "openHeaderModal"
  },
  candidate: {
    openPrivacyFooterModel: "openPrivacyFooterModel",
    openHeaderModel: "openLangModal",
    changeSlotView: "changeSlotView",
    changeToProposedView: "changeToProposedView",
    openRescheduleCancelModel: "openRescheduleCancelModel",
    openScheduledModel: "openScheduledModel",
    openProposedModel: "openProposedModel",
    openSupportModel: "openSupportModel",
    resetHelpDropdown: "resetHelpDropdown",
    openTermsOfUseModal: "openTermsOfUseModal",
    openHeaderModal: "openHeaderModal",
    openJDModal: "openJDModal"
  },
  scheduler: {
    openScreeningAvailabilityCancelModal: "openScreeningAvailabilityCancelModal",
    openScreeningAvilabilityManualModal: "openScreeningAvilabilityManualModal",
    reloadScreeningAvailabilityTabTables: "reloadScreeningAvailabilityTabTables",
    reloadScreeningConfirmedTabTables: "reloadScreeningConfirmedTabTables",
    reloadScreeningModuleTables: "reloadScreeningModuleTables",
    reloadInterviewModuleTables: "reloadInterviewModuleTables",
    openScreeningRescheduleModal: "openScreeningRescheduleModal",
    openScreeningCancelModal: "openScreeningCancelModal",
    openScreeningAdhocModal: "openScreeningAdhocModal",
    openJDModal: "openJDModal",
    openBulkUploadCandidateAddModal: "openBulkUploadCandidateAddModal",
    reloadBulkDraftView: "reloadBulkDraftView",
    openSupportModal: "openSupportModal",
    openInterviewSeqAddEditModal: "openInterviewSeqAddEditModal",
    reloadInterviewTabTable: "reloadInterviewTabTable",
    openTnterviewTabCancelModal: "openTnterviewTabCancelModal",
    openPanelTnterviewTabCancelModal: "openPanelTnterviewTabCancelModal",
    openInterviewSeqDeleteConfirm: "openInterviewSeqDeleteConfirm",
    openPreschedulingAddSlotArea: "openPreschedulingAddSlotArea",
    openPreschedulingEditSlotArea: "openPreschedulingEditSlotArea",
    clearPreschedulingAddSlotArea: "clearPreschedulingAddSlotArea",
    clearPreschedulingEditSlotArea: "clearPreschedulingAddSlotArea",
    openPrivacySubmitModal: "openPrivacySubmitModal",
    openPrivacyViewModal: "openPrivacyViewModal",
    openChangeSequenceModal: "openChangeSequenceModal",
    openAddNewRoundModal: "openAddNewRoundModal",
    openAdhocScheduleModal: "openAdhocScheduleModal",
    deleteSequenceCallback: "deleteSequenceCallback",
    addEditSequenceCallback: "addEditSequenceCallback",
    moveToNextStageModal: "moveToNextStageModal",
    openInterviewRescheduleModal: "openInterviewRescheduleModal",
    openCandidateTabCancelModal: "openCandidateTabCancelModal",
    candidatesReferredByModal: "candidatesReferredByModal",
    openCandidateRescheduleModal: "openCandidateRescheduleModal",
    openTermsOfUseModal: "openTermsOfUseModal",
    openCandidatePipelineEditModal: "openCandidatePipelineEditModal",
    openCandidatePipelineDeleteModal: "openCandidatePipelineDeleteModal",
    openTimezoneLanguageModal: "openTimezoneLanguageModal",
    openDeleteRoundModal: "openDeleteRoundModal",
    openBulkDeleteCanidateModal: "openBulkDeleteCanidateModal",
    confirmDeleteRoundActionModal: "confirmDeleteRoundActionModal",
    openBulkSubmitConfirmModal: "openBulkSubmitConfirmModal",
    confirmedBulkSubmitAction: "confirmedBulkSubmitAction",
    changeInterviewerList: "changeInterviewerList",
    openAddCandidatesPanelModal: "openAddCandidatesPanelModal",
    openActionConfirmationModal: "openActionConfirmationModal",
    openScreeningAddSlotsModal: "openScreeningAddSlotsModal",
    openScreeningEditSlotsModal: "openScreeningEditSlotsModal",
    openCaptureInterviewerAvailabilityModal: "openCaptureInterviewerAvailabilityModal",
    openReleaseSlotsModal: "openReleaseSlotsModal",
    india: {
      openSelectCustomSlotsModal: "openSelectCustomSlotsModal",
      openConfirmSelectSlotModal: "openConfirmSelectSlotModal",
      openCandidateDetailsModal: "openCandidateDetailsModal"
    },
    reloadModeAndToolBasedOnReqId: "reloadModeAndToolBasedOnReqId",
    openBulkErrorModal: "openBulkErrorModal",
    openHeaderModal: "openHeaderModal"
  }
};

/**
 * comments here
 */

export const dtFormat = {
  yymmdd: "yyyy-MM-dd",
  hhmma: "hh:mm a",
  mmmmyyyy: "MMMM yyyy",
  eeemmmdd: "eee, MMM dd",
  stdDtFormat: "dd-MMM-yyyy",
  stdDayDtFormat: "eeee, dd-MMM-yyyy",
  genaralDateTime: "dd-MMM-yyyy, hh:mm a"
};

/**
 * comments here
 */

export const dayIDs = [
  "button-sunday",
  "button-monday",
  "button-tuesday",
  "button-wednesday",
  "button-thursday",
  "button-friday",
  "button-saturday"
];

/**
 * Custom toster messages
 */

export const customMsg = {
  invalid: "Please enter a valid ",
  required: "Please fill all the mandatory fields.",
  selectReason: "Please select the reason to proceed.",
  requiredUnique: "Please fill all the mandatory fields and all the values should be unique.",
  minOne: "Atleast one record should be enabled",
  minModified: "Atleast one round should be modified",
  minCandidate: "Atleast one candidate should be selected",
  prompt: "You have unsaved changed. Do you want to navigate away from this page?",
  duplicate:
    "The date and time should be unique and should not overlap when editing or adding a time slot.",
  errorOccured: "Your request couldn't be processed",
  inprogress: "Development In Progress!",
  candidate: {
    preferencesUpdate: "Successfully updated your preferences",
    preferences: "Successfully submitted your preferences.",
    noEmail: "No Recruiter Email Id present",
    activeSlots: "No slots available.",
    failed: "We’re experiencing a technical issue right now. Please try again later.",
    slotPost: "We've received your preferred slots and will be in touch shortly.",
    slotPostSummary: "New Time Slots Submitted",
    requiredFields: "Please ensure you fill all the mandatory fields marked with a red asterisk*",
    privacy: "Please agree terms and conditions",
    scheduled:
      "Thanks for picking your slot. We will be in touch shortly with your interviewer details and logistics.",
    rescheduled:
      "Your new date and time are confirmed. Please check the details are correct below. We'll be in touch shortly with your interviewer details and logistics.",
    withdrawnSummary: "Successfully Withdrawn Application",
    withdrawn: "Your job application has been successfully withdrawn.",
    scheduledIndia:
      "Thanks for picking your slot. We will be in touch shortly with your interview details and logistics.",
    rescheduledIndia:
      "Your new date and time are confirmed. Please check the details are correct below. We'll be in touch shortly with your interview details and logistics."
  },
  interviewer: {
    noCV: "No resume link present",
    noEmail: "No Recruiter Email Id present",
    processed: "Request has been processed successfully",
    actionFailed: "Action failed. Please try again",
    replacementAsInterviewer: "You cannot add yourself as the replacement interviewer.",
    replacementAsCandidate: "You cannot add candidate as the replacement interviewer.",
    replacementAsRefferer:
      "You cannot add the referrer of the candidate as the replacement interviewer.",
    slotBooked:
      "Interview Slot Already Booked. The interview slot for this candidate has already been confirmed by another interviewer."
  },
  scheduler: {
    ignoreChanges:
      "It looks like you have been editing something. If you leave before saving, your changes will be lost.",
    wdError:
      "The candidate interview rounds that have been successfully saved on SS will only be sent to Workday. Please complete the Draft on SS for the following candidates to send their interview round details to Workday",
    processed: "Request has been processed successfully",
    actionFailed: "Action failed. Please try again",
    roundValidation: "Atleast one round should be enabled",
    roundSelect:
      "Please choose the interview round from the second last column of the table. The above selected values will be applied to the selected round on the dropdown list.",
    reset: "Records has been reset successfully",
    inactive: "If you are marking as inactive, you will not be able to update this in future.",
    privacyAgree: "Please agree terms and conditions",
    prompt:
      "You have unsaved changed in the interviewer sequence tab, Please save the changes or click on reset button to discard the changes",
    uniqueDate:
      "Date and time must be unique when slot is edited/added. Duplicate slots will be removed.",
    selectAtleastOne: "Please select atleast one candidate to proceed",
    swapSeqence: "First select the round before clicking up/down arrows to swap.",
    cancellationFailed: "Interview cancellation failed from WD",
    rescheduleFailed: "Auto reschedule failed from WD",
    feedbackDocumented:
      "Feedback has already been documented for this candidate. Therefore, cannot do this action.",
    interviewerSlotConflict: "Interviewer and time duration cannot be same for all candidates.",
    candidatesAdded: "The candidates added to Smart Scheduling successfully",
    candidatesAddedSummary: "Candidates Added Successfully",
    candidatesUpdatedSummary: "Updated Candidate Details Successfully",
    candidatesUpdated: "The details of the selected candidates are updated successfully",
    sameInterviewer:
      "Either select the Interviewer or time slot while adding details for multiple candidates. As time slot and Interviewer both cannot be same for all candidates unless it is a Panel Interview",
    slotsValidated: "Slot is validated successfully for the group",
    slotsValidationFailed:
      "Validation has failed for selected group, kindly check valid conditions",
    panelPostSuccessSummary: "Panel Interview Added Successfully",
    panelPostSuccess: "The panel interview added to Smart Scheduling Successfully",
    mandatory: "Please fill all the mandatory details",
    verifyPrevRounds:
      "Please verify the previous interview rounds. All previous rounds must be scheduled/feedback completed so that only you can add or update rounds.",
    makeChanges: "Please make changes to at least one group",
    panelUpdateSuccessSummary: "Panel Interview details updated successfully",
    panelUpdateSuccess: "The panel interview updated in Smart Scheduling Successfully",
    sameRequisition:
      "Please select candidates from the same requisition to schedule a Panel Interview",
    makeChangesInBulk: "Please make changes to submit details",
    roundFeedbackCompleted: "Feedback is already completed for this round",
    screenerSlotsCount:
      "Total slots count for all Screeners should be greater than or equal to one more than number of selected Candidates",
    forCandidate: "For candidate ",
    forCandidates: "For candidates ",
    modeValidationMsg:
      ", you need to schedule at least 1 round of interview on video, so see if you want to do this on video. By selecting In-location virtual.",
    newEndDate:
      "The 'End Date' specified in the prescheduling input has elapsed. Kindly provide a new 'End Date' to proceed.",
    cannotReleaseSlots:
      "There are candidates in this prescheduling input who are yet to select slots to schedule interview. Hence, you cannot release slots now",
    selectCandidate:
      "Please select atleast one candidate and/or screener to continue Submit of Screening Input",
    alternateSlot:
      "This slot is already being used for different interview. Please choose alternate slot.",
    candidateSelectionIndiaPanel: "You cannot have more than one selection for India Candidates",
    cannotDeleteSlots:
      "There are candidates in this prescheduling input who are yet to select slots to schedule interview. Hence, you cannot delete all slots.",
    validateEditSlots:
      "The slot you are trying to edit is no longer available for editing, please check the confirmed slots for updates.",
    validateDeleteSlots:
      "The slot you are trying to delete is no longer available for deleting, please check the confirmed slots for updates."
  },
  admin: {
    duplicateRuleSet: "Duplicate rule set found!",
    eidExists: "EnterpriseId already exists!",
    eidNotExists: "EnterpriseId Not exists!",
    processed: "Request has been processed successfully",
    submitted: "Your configuration template is submitted successfully",
    noHierarchy: "No hierarchy found",
    joinRec: "You have to choose at least two rules.",
    special: "Template Name', This field can only contain alphanumeric characters, underscore( _ )",
    duplicate: "Duplicate records found !",
    updateRecords: "No records to Update !"
  }
};

/**
 * Toster message modes
 */

export const msgMode = {
  error: "error",
  info: "info",
  success: "success",
  warning: "warn"
};

/**
 * Toster message modes
 */

export const summary = {
  error: "An error occurred",
  warn: "Warning!",
  alert: "Alert!",
  confirmed: "Interview Confirmed!",
  preferencesSaved: "Your preferences are saved!",
  searchCompleted: "Search Completed!"
};

/**
 * Toster message modes
 */

export const schedularTabs = {
  Summary: { value: 0 },
  "Interview Sequence": { value: 1 },
  "Candidate Pipeline": { value: 2 },
  Prescheduling: { value: 3 },
  Candidates: { value: 4 },
  Interviews: { value: 5 },
  "Slot Status": { value: 6 }
};

/**

 * Configure Tab Index

 */
export const schedularTabsIndex = {
  0: { value: "Summary" },
  1: { value: "Interview Sequence" },
  2: { value: "Candidate Pipeline" },
  3: { value: "Prescheduling" },
  4: { value: "Candidates" },
  5: { value: "Interviews" },
  6: { value: "Slot Status" }
};

/**
 * Toster message modes
 */

export const screeningDetailsTabs = {
  Summary: { value: 0 },
  "Screening Scheduling": { value: 1 },
  Candidates: { value: 2 },
  Interviews: { value: 3 },
  "No Response": { value: 4 }
};

/**

 * Configure Tab Index

 */
export const screeningDetailsTabIndex = {
  0: { value: "Summary" },
  1: { value: "Screening Scheduling" },
  2: { value: "Candidates" },
  3: { value: "Interviews" },
  4: { value: "No Response" }
};

/**
 * Toster message modes
 */

export const screeningAvailabilityTabs = {
  "Screening Availability": { value: 0 },
  "My Confirmed Slots": { value: 1 }
};

/**

 * Configure Tab Index

 */
export const screeningAvailabilityIndex = {
  0: { value: "Screening Availability" },
  1: { value: "My Confirmed Slots" }
};

/**
 * Toster message modes
 */

export const adminTabs = {
  "Interview Details": { value: 0 },
  "Questionnaires Mapping": { value: 1 },
  "Channel Hierarchy": { value: 2 },
  "Candidate Prioritization": { value: 3 },
  "Data Privacy": { value: 4 },
  "Scheduling Master Data": { value: 5 },
  "Facility Capacity Master": { value: 6 }
};

/**
 * Toster message modes
 */

export const schedularBulkUploadTabs = {
  "Draft On SmartScheduling": { value: 0 },
  "Sent to Workday": { value: 1 }
};

/**
 * Toster message modes
 */

export const schedularBulkUploadTabsIndex = {
  0: { value: "Draft On SmartScheduling" },
  1: { value: "Sent to Workday" }
};

/**
 * Toster message modes
 */

export const monthData = {
  1: { value: "January" },
  2: { value: "February" },
  3: { value: "March" },
  4: { value: "April" },
  5: { value: "May" },
  6: { value: "June" },
  7: { value: "July" },
  8: { value: "August" },
  9: { value: "September" },
  10: { value: "October" },
  11: { value: "November" },
  12: { value: "December" }
};

/**
 * comments here
 */

export const tabElement = [
  "custom",
  "button",
  "a",
  "input",
  "textarea",
  "label",
  "span",
  "h5",
  "em",
  "b"
];
/**
 * comments here
 */

export const nonTabElements = [
  "p-accordion-header-text",
  "p-paginator-pages",
  "p-multiselect-close p-link",
  "p-dropdown-label p-inputtext",
  "p-dropdown-trigger-icon p-clickable pi pi-chevron-down",
  "p-dropdown-label p-inputtext p-placeholder",
  "p-disabled",
  "p-datepicker-prev p-link",
  "p-datepicker-next p-link",
  "no-focus",
  "reschedule-available-slot slot-size border-seperation w-100 text-inherit pointer reschedule-disable-slot",
  "bell-tool p-overlay-badge",
  "nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center",
  "p-badge p-component p-badge-no-gutter p-badge-danger",
  "p-button-icon p-c pi pi-calendar",
  "p-multiselect-trigger-icon p-c pi pi-chevron-down",
  "p-calendar p-component p-inputwrapper inline-reschedule-calendar",
  "availability-bg-color slot-size border-seperation w-100 reschedule-disable-slot",
  "availability-bg-color slot-size border-seperation w-100",
  "accepted-bg-color slot-size border-seperation w-100 reschedule-disable-slot",
  "accepted-bg-color slot-size border-seperation w-100",
  "scheduled-bg-color slot-size border-seperation w-100 reschedule-disable-slot",
  "scheduled-bg-color slot-size border-seperation w-100",
  "graphApi-bg-color slot-size border-seperation w-100 reschedule-disable-slot",
  "graphApi-bg-color slot-size border-seperation w-100",
  "card-title mb-0 full-width-100 font-12 dark-text regular-font",
  "btn p-0 w-100 no-focus",
  "blue-btn btn-padding no-focus",
  "p-scrolltop-icon pi pi-chevron-up",
  "pointer pi pi-angle-left disabled-row",
  "pointer pi pi-angle-right disabled-row",
  "btn btn-primary outline-btns mar-t-1 disabled",
  "btn btn-primary outline-btns disabled",
  "p-row-toggler-icon pi pi-chevron-right",
  "p-row-toggler-icon pi pi-chevron-down",
  "panel-slot-border-left",
  "panel-slot-border-right",
  "p-listbox-filter-icon pi pi-search",
  "p-calendar p-component p-inputwrapper inline-calendar"
];

/**
 * comments here
 */

export const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

/**
 * comments here
 */

export const timeSlots = [
  "12:00 AM",
  "12:30 AM",
  "01:00 AM",
  "01:30 AM",
  "02:00 AM",
  "02:30 AM",
  "03:00 AM",
  "03:30 AM",
  "04:00 AM",
  "04:30 AM",
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 PM",
  "09:00 PM",
  "09:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM"
];

/**
 * comments here
 */

export const timeSlotsNew = [
  "12:00 AM",
  "12:15 AM",
  "12:30 AM",
  "12:45 AM",
  "01:00 AM",
  "01:15 AM",
  "01:30 AM",
  "01:45 AM",
  "02:00 AM",
  "02:15 AM",
  "02:30 AM",
  "02:45 AM",
  "03:00 AM",
  "03:15 AM",
  "03:30 AM",
  "03:45 AM",
  "04:00 AM",
  "04:15 AM",
  "04:30 AM",
  "04:45 AM",
  "05:00 AM",
  "05:15 AM",
  "05:30 AM",
  "05:45 AM",
  "06:00 AM",
  "06:15 AM",
  "06:30 AM",
  "06:45 AM",
  "07:00 AM",
  "07:15 AM",
  "07:30 AM",
  "07:45 AM",
  "08:00 AM",
  "08:15 AM",
  "08:30 AM",
  "08:45 AM",
  "09:00 AM",
  "09:15 AM",
  "09:30 AM",
  "09:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "01:00 PM",
  "01:15 PM",
  "01:30 PM",
  "01:45 PM",
  "02:00 PM",
  "02:15 PM",
  "02:30 PM",
  "02:45 PM",
  "03:00 PM",
  "03:15 PM",
  "03:30 PM",
  "03:45 PM",
  "04:00 PM",
  "04:15 PM",
  "04:30 PM",
  "04:45 PM",
  "05:00 PM",
  "05:15 PM",
  "05:30 PM",
  "05:45 PM",
  "06:00 PM",
  "06:15 PM",
  "06:30 PM",
  "06:45 PM",
  "07:00 PM",
  "07:15 PM",
  "07:30 PM",
  "07:45 PM",
  "08:00 PM",
  "08:15 PM",
  "08:30 PM",
  "08:45 PM",
  "09:00 PM",
  "09:15 PM",
  "09:30 PM",
  "09:45 PM",
  "10:00 PM",
  "10:15 PM",
  "10:30 PM",
  "10:45 PM",
  "11:00 PM",
  "11:15 PM",
  "11:30 PM",
  "11:45 PM"
];

/**
 * comments here
 */

export const candidateStaticData = {
  status: [
    "Scheduled for Interview",
    "Awaiting Recruiter Confirmation",
    "Awaiting Your Confirmation"
  ],
  helpDropdown: [
    { name: "I can't make these slots. Please suggest other times.", id: "IN_0" },
    { name: "I have question for the Recruiter.", id: "IN_2" }
  ]
};

/**
 * comments here
 */

export const checkChangedValues = ["startDate", "endDate", "duration"];

/**
 * comments here
 */

export const roundTypes = { panel: "Panel", nonPanel: "non-panel" };

/**
 * comments here
 */

export const weekViewOptions = [
  { id: "opt_0", name: "all" },
  { id: "opt_1", name: "weekly" },
  { id: "opt_2", name: "daily" }
];

/**
 * comments here
 */

export const feedbackReasons = [
  "Candidate No Show - Manually Reschedule",
  "Interviewer No Show - Manually Reschedule",
  "Others - Manually Reschedule"
];

/**
 * comments here
 */

export const schedulerBulkTabOverlay = [
  { name: "Link to JD", fn: "openJDModal" },
  { name: "Link to CV", fn: "openCVModal" },
  { name: "Remove Candidate", fn: "openDeleteCandidateModal" }
];

/**
 * comments here
 */

export const candidateStatus = {
  dashboard: [
    "Scheduled for Screening",
    "Scheduled for Interview",
    "Scheduled for Screening (Adhoc)"
  ],
  slotsView: [
    "Scheduled for Screening",
    "Scheduled for Interview",
    "Awaiting Recruiter Confirmation",
    "Scheduled for Screening (Adhoc)"
  ],
  viewDetails: [
    "Scheduled for Interview",
    "Awaiting Interviewer Confirmation",
    "Awaiting Your Confirmation",
    "Scheduled for Screening",
    "Scheduled for Screening (Adhoc)"
  ],
  scheduled: ["ScheduledforInterview", "ScheduledforScreening", "ScheduledforScreening(Adhoc)"]
};

/**
 * comments here
 */

export const candTabOverLay = {
  "Ready for Interview": [
    { name: "Ad-hoc Schedule", fn: "openAdhocScheduleModal", type: "All" },
    { name: "View Details", fn: "openPreschedulingPanelAddModal", type: roundTypes.panel }
  ],
  "Ready for Interview (Panel)": [
    { name: "View Details", fn: "openPreschedulingPanelAddModal", type: roundTypes.panel }
  ],
  Reactivated: [
    { name: "Ad-hoc Schedule", fn: "openAdhocScheduleModal", type: "All" },
    { name: "View Details", fn: "openPreschedulingPanelAddModal", type: roundTypes.panel }
  ],
  "Reactivated (Panel)": [
    { name: "View Details", fn: "openPreschedulingPanelAddModal", type: roundTypes.panel }
  ],
  "Interview Cleared": [{ name: "Move to next stage on WD", fn: "moveToNextStageModal" }],
  "Interview Cancelled": [
    { name: "Ad-hoc Schedule", fn: "openAdhocScheduleModal", type: "All" },
    { name: "View Details", fn: "openPreschedulingPanelAddModal", type: roundTypes.panel }
  ],
  "Interview Cancelled (Panel)": [
    { name: "View Details", fn: "openPreschedulingPanelAddModal", type: roundTypes.panel }
  ],
  "Ready for Interview (Adhoc)": [{ name: "Ad-hoc Schedule", fn: "openAdhocScheduleModal" }],
  "Prescheduled for Interview": [
    { name: "Ad-hoc Schedule", fn: "openAdhocScheduleModal", type: "All" }
  ],
  "Prescheduled for Interview (Adhoc)": [{ name: "Ad-hoc Schedule", fn: "openAdhocScheduleModal" }],
  "Mail Sent to Candidate": [
    { name: "Ad-hoc Schedule", fn: "openAdhocScheduleModal", type: "All" }
  ],
  "Mail Sent to Candidate (Adhoc)": [{ name: "Ad-hoc Schedule", fn: "openAdhocScheduleModal" }],
  "Proposed Alternate Slots": [
    { name: "Ad-hoc Schedule", fn: "openAdhocScheduleModal", type: "All" },
    { name: "View Details", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel }
  ],
  "Proposed Alternate Slots (Adhoc)": [{ name: "Ad-hoc Schedule", fn: "openAdhocScheduleModal" }],
  "Proposed Alternate Slots (Panel)": [
    { name: "View Details", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel }
  ],
  "Interview Rescheduled - Others": [
    { name: "Ad-hoc Schedule", fn: "openAdhocScheduleModal", type: "All" },
    { name: "View Details", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel }
  ],
  "Interview Rescheduled - (Panel)": [
    { name: "View Details", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel }
  ],
  "Scheduled for Interview": [
    { name: "Reschedule", fn: "openCandidateRescheduleModal", type: "All" },
    { name: "Cancel Interview", fn: "cancelInterviewModal", type: "All" },
    { name: "Reschedule", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel },
    { name: "Cancel Interview", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel }
  ],
  "Scheduled for Interview (Panel)": [
    { name: "Reschedule", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel },
    { name: "Cancel Interview", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel }
  ],
  "Scheduled for Interview (Adhoc)": [
    { name: "Reschedule", fn: "openCandidateRescheduleModal", type: "All" },
    { name: "Cancel Interview", fn: "cancelInterviewModal", type: "All" },
    { name: "Reschedule", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel },
    { name: "Cancel Interview", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel }
  ],
  "Feedback Documented": [{ name: "Reschedule", fn: "openCandidateRescheduleModal" }],
  "Scheduled for Interview (Bulk)": [{ name: "View Schedule", fn: "viewSchedule" }],
  "Interview Completed (Awaiting Feedback)": [
    { name: "Reschedule", fn: "openCandidateRescheduleModal", type: "All" },
    { name: "Cancel Interview", fn: "cancelInterviewModal", type: "All" }
  ],
  "Mail Sent to Candidate - No EIs found": [
    { name: "Ad-hoc Schedule", fn: "openAdhocScheduleModal", type: "All" }
  ],
  "Interview Scheduled (Automatic)": [
    { name: "Reschedule", fn: "openCandidateRescheduleModal", type: "All" },
    { name: "Cancel Interview", fn: "cancelInterviewModal", type: "All" }
  ]
};

/**
 * comments here
 */

export const scheduledTypes = [
  "Scheduled for Interview",
  "Scheduled for Interview (Adhoc)",
  "Ready for Interview",
  "Reactivated",
  "Interview Cancelled",
  "Proposed Alternate Slots"
];

/**
 * comments here
 */

export const schedulerInterviewTab = {
  "Scheduled for Interview": [
    { name: "Reschedule", fn: "openInterviewRescheduleModal", type: "All" },
    { name: "Cancel Interview", fn: "cancelInterviewModal", type: "All" },
    { name: "Reschedule", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel },
    { name: "Cancel Interview", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel }
  ],
  "Scheduled for Interview (Panel)": [
    { name: "Reschedule", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel },
    { name: "Cancel Interview", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel }
  ],
  "Scheduled for Interview (Adhoc)": [
    { name: "Reschedule", fn: "openInterviewRescheduleModal", type: "All" },
    { name: "Cancel Interview", fn: "cancelInterviewModal", type: "All" },
    { name: "Reschedule", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel },
    { name: "Cancel Interview", fn: "openPreschedulingPanelEditModal", type: roundTypes.panel }
  ],
  "Scheduled for Interview (Bulk)": [{ name: "View Schedule", fn: "viewSchedule" }],
  "Interview Scheduled (Automatic)": [
    { name: "Reschedule", fn: "openInterviewRescheduleModal", type: "All" },
    { name: "Cancel Interview", fn: "cancelInterviewModal", type: "All" }
  ]
};

/**
 * comments here
 */

export const interviewerOverlayItems = {
  "Awaiting Candidate Confirmation": [
    { name: "Propose Alternate Slot", id: "proposeAlternateSlot", fn: "openProposeAlternateSlot" },
    { name: "Link to JD", id: "linkToJd", fn: "openJD" },
    { name: "Contact Recruiter via email", id: "contactRecruiter", fn: "openEmail" }
  ],
  "Interview Scheduled": [
    { name: "Reschedule", id: "reschedule", fn: "openReschedule" },
    { name: "Cancel", id: "cancel", fn: "openCancel" },
    { name: "Link to JD", id: "linkToJd", fn: "openJD" },
    { name: "Link to CV", id: "linkToCv", fn: "openCV" },
    { name: "Contact Recruiter via email", id: "contactRecruiter", fn: "openEmail" }
  ],
  "Awaiting Slot Confirmation": [
    { name: "Accept", id: "accept", fn: "openAcceptModal" },
    { name: "Decline", id: "decline", fn: "openDeclineModal" },
    { name: "Link to JD", id: "linkToJd", fn: "openJD" },
    { name: "Propose Alternate Slot", id: "proposeAlternateSlot", fn: "openProposeAlternateSlot" },
    { name: "Contact Recruiter via email", id: "contactRecruiter", fn: "openEmail" }
  ],
  "Candidate Nominated Slots": [
    { name: "Accept", id: "accept", fn: "openNominatedAcceptModal" },
    { name: "Contact Recruiter via email", id: "contactRecruiter", fn: "openEmail" }
  ],
  "Awaiting Slot Confirmation - India": [
    { name: "Accept", id: "accept", fn: "openAcceptModal" },
    { name: "Decline", id: "decline", fn: "openDeclineModal" },
    { name: "Link to JD", id: "linkToJd", fn: "openJD" },
    { name: "Contact Recruiter via email", id: "contactRecruiter", fn: "openEmail" }
  ]
};

/**
 * comments here
 */

export const screeningCandidateTab = {
  "Ready for Screening": [{ name: "Adhoc Schedule", fn: "openScreeningAdhocModal" }],
  "Scheduled for Screening": [
    { name: "Reschedule", fn: "openScreeningRescheduleModal" },
    { name: "Cancel Screening", fn: "openScreeningCancelModal" }
  ],
  "Mail Sent to Candidate": [
    { name: "Adhoc Schedule", fn: "openScreeningAdhocModal" },
    { name: "Edit Slot", fn: "openScreeningEditSlotsModal" },
    { name: "Delete", fn: "openScreeningAvailabilityCancelModal" }
  ],
  "Proposed Alternate Slots": [{ name: "Adhoc Schedule", fn: "openScreeningAdhocModal" }],
  "Scheduled for Screening (Adhoc)": [
    { name: "Reschedule", fn: "openScreeningRescheduleModal" },
    { name: "Cancel Screening", fn: "openScreeningCancelModal" }
  ],
  "Screening Cancelled": [{ name: "Adhoc Schedule", fn: "openScreeningAdhocModal" }],
  "No Response Tab": [{ name: "Adhoc Schedule", fn: "openScreeningAdhocModal" }]
};

/**
 * comments here
 */

export const screeningConfirmedType = {
  confirmed: "Confirmed Slots",
  awaiting: "Awaiting Confirmation"
};

/**
 * comments here
 */

export const screeningConfirmedStatus = {
  scheduled: "Scheduled for Screening",
  scheduledAdhoc: "Scheduled for Screening (Adhoc)",
  mailSent: "Mail Sent to Candidate"
};

/**
 * comments here
 */

export const screeningStatus = {
  reschedule: "Reschedule",
  adhocSchedule: "Adhoc Schedule",
  screeningScheduled: "Scheduled for Screening",
  screeningAdhoc: "Scheduled for Screening (Adhoc)"
};

/**
 * comments here
 */

export const panelValidationOverlay = [
  {
    languageKey: "interviewerCandidateCount",
    groupValue: "combinedCount"
  },
  {
    languageKey: "supportPersonnelCount",
    groupValue: "personnelCount"
  },
  {
    languageKey: "supportPersonnelValidation",
    groupValue: "personnelSameAsInterviewer"
  },
  {
    languageKey: "candidateValidation",
    groupValue: "otherGroupCandidatePresent"
  },
  {
    languageKey: "interviewersValidation",
    groupValue: "sameSelectedSlotPresent"
  },
  {
    languageKey: "selectedSlotValidation",
    groupValue: "validSelectedSlot"
  },
  {
    languageKey: "slotInRange",
    groupValue: "validateSlotInRange"
  },
  {
    languageKey: "noteValidation",
    groupValue: "invalidNotesCount"
  }
];

/**
 * comments here
 */

export const panelInBulkValidationOverlay = [
  {
    languageKey: "Please fill all the mandatory details",
    groupValue: "mandatoryDetails"
  },
  {
    languageKey: "interviewerCandidateCount",
    groupValue: "combinedCount"
  },
  {
    languageKey: "supportPersonnelCount",
    groupValue: "personnelCount"
  },
  {
    languageKey: "supportPersonnelValidation",
    groupValue: "personnelSameAsInterviewer"
  },
  {
    languageKey: "selectedSlotValidation",
    groupValue: "validSelectedSlot"
  },
  {
    languageKey: "slotInRange",
    groupValue: "validateSlotInRange"
  }
];

/**
 * comments here
 */

export const interviewerModal = {
  header: "Need Support",
  paragraph:
    "Contact us if you are experiencing technical problems or have technical questions that you need help with. Your recruiter will be happy to help with all non-technical questions.",
  contacts: {
    emailAddress: "support.smartscheduling@accenture.com",
    phone1: "011-12345670",
    phone2: "011-12345671"
  }
};

/**
 * comments here
 */

export const schedulerModal = {
  header: "Need Support",
  paragraph:
    "Contact us if you are experiencing technical problems or have technical questions that you need help with.",
  contacts: {
    emailAddress: "support.smartscheduling@accenture.com",
    phone1: "011-12345670",
    phone2: "011-12345671"
  }
};

/**
 * comments here
 */

export const InterviewerNotifications = [];

/**
 * comments here
 */

export const SchedularNotifications = [];

/**
 * comments here
 */

export const CandidateCancelOptions = [
  {
    label: "I want to reschedule",
    value: "R"
  },
  {
    label: "I want to speak with the recruiter",
    value: "E"
  }
];

/**
 * comments here
 */

export const DisableInterviewTools = "In-Location-F2F";

export const AndOr = [
  { name: "And", code: "AND" },
  { name: "Or", code: "OR" }
];

/**
 * comments here
 */

export const Operator = [
  { name: "=", value: "=" },
  { name: "<>", value: "<>" }
];

/**
 * comments here
 */

export const landingSetting = {
  scheduler: { icon: "landing_Icon_scheduling.png", label: "Recruiter" },
  admin: { icon: "landing_Icon_admin.png", label: "Admin" },
  interviewer: { icon: "landing_Icon_interviewer.png", label: "Interviewer" },
  candidate: { icon: "landing_Icon_candidate.png", label: "Candidate" }
};

/**
 * comments here
 */

export const roundStatus = {
  uiReschedule: "reschedule",
  uiCancel: "cancel",
  uiSchedule: "schedule",
  draftSchedule: "Scheduled",
  newlyAdded: "Newly Added",
  draftedInSS: "Drafted in SS",
  readyForInterview: "Ready for Interview",
  feedbackCompleted: "Feedback Completed"
};

/**
 * comments here
 */

export const bulkRoundsIcons = {
  Completed: { icon: "success-icon round-icon" },
  "In Progress": { icon: "inprogress-icon round-icon" },
  Scheduled: { icon: "draftinprogress-icon round-icon" },
  "Ready for Interview": { icon: "readyforinterview-icon round-icon" }
};

/**
 * comments here
 */

export const bulkTableIcons = {
  "Drafted in SS": { icon: "success-icon round-icon" },
  Errors: { icon: "excla-icon round-icon" },
  "Newly Added": { icon: "newlyadded-icon round-icon" },
  "Draft in progress": { icon: "draftinprogress-icon round-icon" },
  "Feedback Completed": { icon: "success-icon round-icon" },
  "Feedback in progress": { icon: "draftinprogress-icon round-icon" },
  "Awaiting Feedback": { icon: "newlyadded-icon round-icon" },
  Declined: { icon: "excla-icon round-icon" }
};

/**
 * comments here
 */

export const slotColors = {
  Blocked: { color: "#FCD34D" },
  Scheduled: { color: "#EF4444" },
  Nominated: { color: "#66BBFF" }
};

/**
 * comments here
 */

export const screeningSlotColors = {
  Scheduled: "scheduled-bg-color",
  Nominated: "availability-bg-color",
  Blocked: "accepted-bg-color"
};

/**
 * comments here
 */

export const screeningSlotLabels = {
  "scheduled-bg-color": "This time slot is scheduled for other interview",
  "availability-bg-color": "This time slot is added as availability",
  "accepted-bg-color": "This time slot is blocked for meeting or PTO or training"
};

/**
 * comments here
 */

export const getSupportUrl = {
  internal:
    "https://support.accenture.com/support_portal?id=acn_service_catalog_dp&sys_id=c9856641139a6600380ddbf18144b05f"
};

/**
 * comments here
 */

addLocale("EN", {
  dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
});
addLocale("ES", {
  dayNamesMin: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ]
});
addLocale("IT", {
  dayNamesMin: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
  monthNames: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre"
  ]
});
addLocale("FR", {
  dayNamesMin: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aoà»t",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
  ]
});
addLocale("DUT", {
  dayNamesMin: ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"],
  monthNames: [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December"
  ]
});
addLocale("PL", {
  dayNamesMin: ["Nie", "Dim", "Wto", "Sro", "Czw", "Pią", "Sob"],
  monthNames: [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień"
  ]
});
addLocale("PT", {
  dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
  monthNames: [
    "Janeiro",
    "Fevereiro ",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ]
});
addLocale("ESL", {
  dayNamesMin: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ]
});
addLocale("JA", {
  dayNamesMin: ["日曜日", "月", "火", "水", "木", "金", "土"],
  monthNames: [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月"
  ]
});

/**
 * comments here
 */

export const KEYS = {
  interviewer: "groupPanelInterviewers",
  candidate: "groupPanelInterviewCandidates",
  supportPersonal: "supportPersonnels",
  eID: "enterpriseId",
  cID: "candidateId",
  ssID: "ssInterviewTypeId"
};

/**
 * comments here
 */

export const customDateNavigator = (e) => {
  return (
    <Dropdown
      optionLabel="label"
      optionValue="value"
      appendTo="self"
      showOnFocus={true}
      value={e.value}
      options={e.options}
      className="header-dropdown"
      onChange={(event) => {
        e.onChange(event.originalEvent, event.value);
        let selected = e.options?.filter((a) => a["value"] == event.value)[0];
        const label = selected?.label.toString();
        window.enableAudio(label);
      }}
    />
  );
};

/**
 * comments here
 */

export const paginationTemplate = (lang, length) => {
  return {
    layout: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    FirstPageLink: (options) => {
      return (
        <button
          type="button"
          aria-label={lang && lang("goToFirstPage")}
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}>
          <div className="ont-12">
            <i className="pi pi-backward"></i>
          </div>
        </button>
      );
    },
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          aria-label={lang && lang("goToPreviousPage")}
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}>
          <div className="ont-12">
            <i className="pi pi-caret-left"></i>
          </div>
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          aria-label={lang && lang("goToNextPage")}
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}>
          <div className="font-12">
            {" "}
            <i className="pi pi-caret-right"></i>
          </div>
        </button>
      );
    },
    LastPageLink: (options) => {
      return (
        <button
          aria-label={lang && lang("goToLastPage")}
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}>
          <div className="font-12">
            {" "}
            <i className="pi pi-forward"></i>
          </div>
        </button>
      );
    },
    PageLinks: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          aria-label={lang && lang("Page Number ") + (options.page + 1)}>
          {options.page + 1}
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: "All", value: length ? length : options.totalRecords }
      ];
      return (
        <Dropdown
          showOnFocus={true}
          appendTo={"self"}
          ariaLabel={lang && lang("Per Page Count is ") + options.value}
          value={options.value}
          options={dropdownOptions}
          onChange={options.onChange}
        />
      );
    }
  };
};

/**
 * comments here
 */

export const masterConfigRequestPayload = [
  {
    configurationKey: "ThresholdNumberOfCandidatesInPanel"
  },
  {
    configurationKey: "ThresholdNumberOfInterviewersInPanel"
  }
];

/**
 * comments here
 */

export const supportLinksList = [
  {
    heading: "Watch our Feedback Training.",
    content:
      "Drives great hiring decisions and candidate experiences.Please see the following feedback essentials.Click on learn more...",
    imageUrl: "training.jpg",
    viewMore: "https://app.socialtalent.com/explore/learning-path/6468dc6e384ef65e487d1cbf",
    icon: "pi pi-home"
  },
  {
    heading: "Write great feedback",
    content:
      "Drives great hiring decisions and candidate experiences.Please see the following feedback essentials.Click on learn more...",
    imageUrl: "feedback.jpg",
    viewMore:
      "https://ts.accenture.com/:b:/s/Recruiting_Learning/ETQUgClEEXJFv727kKnyS_YBoS_cgT2oB9xUcdFanc0WyA?e=blSzEJ",
    icon: "pi pi-pencil"
  },
  {
    heading: "New to Social Talent? ",
    content:
      "Drives great hiring decisions and candidate experiences.Please see the following feedback essentials.Click on learn more...",
    imageUrl: "tenent.jpg",
    viewMore:
      "https://ts.accenture.com/:b:/s/Recruiting_Learning/EZXvLmMWWANCkAq5HCJwORwByxt1rCmNNUU7mF2gY_nL8A?e=f2vTd8",
    icon: "pi pi-home"
  }
];

/**
 * comments here
 */

export const weekOptions = [
  { id: "Sunday", name: "S", key: 0 },
  { id: "Monday", name: "M", key: 1 },
  { id: "Tuesday", name: "T", key: 2 },
  { id: "Wednesday", name: "W", key: 3 },
  { id: "Thursday", name: "TH", key: 4 },
  { id: "Friday", name: "F", key: 5 },
  { id: "Saturday", name: "ST", key: 6 }
];

/**
 * comments here
 */

export const dayArray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

/**
 * comments here
 */

export const carouselConfig = [
  {
    breakpoint: "1024px",
    numVisible: 3,
    numScroll: 3
  },
  {
    breakpoint: "600px",
    numVisible: 2,
    numScroll: 2
  },
  {
    breakpoint: "480px",
    numVisible: 1,
    numScroll: 1
  }
];

/**
 * comments here
 */

export const configPayload = [
  {
    configurationKey: "ThresholdNumberOfCandidatesInPanel"
  },
  {
    configurationKey: "ThresholdNumberOfInterviewersInPanel"
  }
];

/**
 * Overlay for India Panel
 */

export const panelInBulkValidationOverlayForIndia = [
  {
    languageKey: "Please fill all the mandatory details",
    groupValue: "mandatoryDetails"
  },
  {
    languageKey: "supportPersonnelCountForIndia",
    groupValue: "personnelCount"
  },
  {
    languageKey: "supportPersonnelValidation",
    groupValue: "personnelSameAsInterviewer"
  },
  {
    languageKey: "selectedSlotValidation",
    groupValue: "validSelectedSlot"
  },
  {
    languageKey: "slotInRange",
    groupValue: "validateSlotInRange"
  }
];
